<template>
  <div class="login d-flex justify-content-center">
    <div class="card login-form">
      <div class="card-body">
        <div class="text-center mt-4">
          <div class="position-relative">
            <img
              src="../assets/img/icon.png"
              width="70"
              height="70"
              alt="logo"
            />
            <span class="position-relative">
              <span class="align-bottom">Hub</span>
            </span>
          </div>
          <hr />
          <h5 class="card-title fw-light mt-4">Log in</h5>
        </div>
        <div class="card-text">
          <ValidationObserver
            tag="form"
            class="mt-4"
            v-slot="{ handleSubmit }"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <ValidationProvider
              tag="div"
              rules="required|email"
              name="Email"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="loginEmail" class="form-label">Email address</label>
              <input
                type="email"
                id="loginEmail"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                v-model="login_data.email"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              name="Password"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="loginPassword" class="form-label">Password</label>
              <div class="input-group">
                <input
                  id="loginPassword"
                  :type="[showPassword ? 'text' : 'password']"
                  :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                  v-model="login_data.password"
                />
                <button
                  type="button"
                  class="btn btn-outline-secondary mt-0"
                  @click="showPassword = !showPassword"
                >
                  <i
                    :class="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']"
                  ></i>
                </button>
              </div>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <div
              v-if="response_message"
              class="alert alert-danger text-center"
              role="alert"
            >
              {{ response_message }}
            </div>
            <div class="text-center">
              <button
                type="submit"
                class="btn btn-primary mt-4 px-4"
                :disabled="isLoading"
                @click.prevent="handleSubmit(loginUser)"
              >
                Sign in
                <loader :isLoading="isLoading"></loader>
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mutations } from "../store";
import loader from "../components/loader";
export default {
  data() {
    return {
      login_data: {
        email: "",
        password: "",
      },
      isLoading: false,
      showPassword: false,
      response_message: "",
    };
  },
  components: {
    loader,
  },
  methods: {
    loginUser() {
      this.isLoading = true;
      this.$axios
        .post("/users/login", this.login_data)
        .then(({ data: { success, message, loggedInUser, token } }) => {
          this.isLoading = false;
          if (success) {
            mutations.setLocalStorage(loggedInUser, token);
          } else {
            this.response_message = message;
          }
        })
        .catch(() => {
          this.response_message = "Please try again!";
        });
    },
  },
};
</script>
